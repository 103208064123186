import React, { Dispatch, SetStateAction, useRef, useState } from 'react';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { McButton, McModal } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useSnackbar } from 'notistack';
import { AdjustableComponent, useClasses } from '../../common/hooks/useClasses';
import { FormattedMessage } from 'react-intl';
import FormikInputField from '../../common/components/FormikInputField/FormikInputField';
import { DependencyContainer } from '../../../http/DependencyContainer';
import Drawer from '../../common/components/Drawer/Drawer';

export type AddDefaultValuesDrawerStyles = {
  field: string;
  buttons: string;
};

export type AddDefaultValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchRules: () => void;
  selectedTerminal: string;
  selectedUser: any;
};

export type EditRuleFormShape = {
  terminalId: string;
  id: string;
  email: string;
};

const FormValidationSchema = z.object({
  terminalId: z.string(),
  email: z.string(),
});

const { usersService } = new DependencyContainer();

const EditUserDrawer: AdjustableComponent<AddDefaultValuesDrawerProps, AddDefaultValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  fetchRules,
  selectedTerminal,
  selectedUser,
}) => {
  const styles = useClasses(
    {
      field: 'AddDefaultValuesDrawer__field',
      buttons: 'AddDefaultValuesDrawer__buttons',
    },
    classes,
  );
  const { enqueueSnackbar } = useSnackbar();
  const deleteModalRef = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const formikRef = useRef(null);
  const onClose = () => {
    setOpen(false);
  };
  const getFormInitialValues = (): EditRuleFormShape => {
    return {
      terminalId: selectedTerminal,
      id: selectedUser?.id,
      email: selectedUser?.email,
    };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    console.log('On submit');
    setSubmitting(true);
    try {
      await usersService.addUser(selectedTerminal, values.email);
      enqueueSnackbar('User added', {
        variant: 'success',
      });
      await fetchRules();
      onClose();
    } catch (error) {
      const message = 'User not found for email: ' + values.email;
      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer title={'Edit user'} open={open} setOpen={setOpen} onRequestClose={onClose} noFooter>
      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        validationSchema={toFormikValidationSchema(FormValidationSchema)}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting }) => (
          <Form>
            <div>
              <Field label={'Email'} type="text" name="email" disabled component={FormikInputField} />
              <ErrorMessage name="email" component="div" />
            </div>

            <Field type="hidden" name="terminalId" />
            <div className={styles.buttons}>
              <McButton disabled={true} type="submit">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
              <McButton type="button" appearance="neutral" click={() => onClose()}>
                <FormattedMessage id="cancel" />
              </McButton>

              <McButton
                type="button"
                appearance="error"
                click={() => {
                  if (deleteModalRef.current) {
                    // @ts-ignore
                    deleteModalRef.current.show();
                  }
                }}
              >
                Delete
              </McButton>
            </div>

            <McModal ref={deleteModalRef} dimension={'small'} heading={'Revoke Access'}>
              <p id={'question'}>Are you sure you want to revoke access for this user?</p>
              <McButton
                slot="secondaryAction"
                appearance="neutral"
                variant="outlined"
                dialogaction="cancel"
                label="Cancel"
              ></McButton>
              <McButton
                id="confirm"
                slot="primaryAction"
                appearance="primary"
                loading={deleteLoading}
                click={async () => {
                  setDeleteLoading(true);
                  // @ts-ignore
                  await usersService.deleteUser(selectedTerminal, selectedUser?.id);
                  enqueueSnackbar('User access revoked from ' + selectedTerminal, {
                    variant: 'success',
                  });
                  fetchRules();
                  onClose();
                  setDeleteLoading(false);
                  // @ts-ignore
                  deleteModalRef.current.close();
                }}
                label="Delete"
              ></McButton>
            </McModal>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditUserDrawer;
