import { Maybe } from '../../../common/types';
import { EditOccFormShape } from '../EditOccValuesDrawer/EditOccValuesDrawer';
import { mapLabelToCapacityType } from '../utils/capacityTypeMapper';

export type UpdateOccDto = {
  vesselPairLimits: Maybe<number>;
  from: Maybe<string>;
  to: Maybe<string>;
  terminalCodeAndType: Maybe<string>;
};

export const createOccDto = (formData: EditOccFormShape): UpdateOccDto => {
  return {
    vesselPairLimits: formData.vesselPairLimits,
    from: mapLabelToCapacityType(formData.from),
    to: mapLabelToCapacityType(formData.to),
    terminalCodeAndType: formData.terminalCode + '-' + formData.dateType,
  };
};
