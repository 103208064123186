import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  McLoadingIndicator,
  McNotification,
  McTable,
  McOption,
  McButton,
  McSelect,
  McTabBar,
  McTab,
  McSegmentedControl,
  McSegmentedControlItem,
} from '@maersk-global/mds-react-wrapper';
import { isCarriersAvailableForTerminal } from '../../common/helpers/carriers';
import EditDefaultValuesDrawer from './EditDefaultValuesDrawer/EditDefaultValuesDrawer';
import EditWeeklyValuesDrawer from './EditWeeklyValuesDrawer/EditWeeklyValuesDrawer';
import { mapCapacityType } from './utils/capacityTypeMapper';
import EditOccValuesDrawer from './EditOccValuesDrawer/EditOccValuesDrawer';
import { modifyDateWeek } from '../../common/helpers/modify-date';
import AddDefaultValuesDrawer from './AddDefaultValuesDrawer/AddDefaultValuesDrawer';
import useAuth from '../../common/hooks/useAuth';
import { terminals } from '../../common/helpers/terminals';
import TerminalCarriersRenderer from '../components/TerminalCarriersRenderer/TerminalCarriersRenderer';
import useCapacityData from '../hooks/useCapacityData';
import { defaultColumns, weeklyColumns } from '../config/capacity-columns';
import { defaultDataMapper, weeklyDataMapper } from '../config/mappers';
import { useRecoilState } from 'recoil';
import { terminalCodeAtom } from '../../../state';

const CapacityIdContainer: React.FC = () => {
  const { userData } = useAuth();
  const occTableRef = useRef(null);
  const defaultTableRef = useRef(null);
  const weeklyTableRef = useRef(null);
  const { id } = useParams<{ id: string }>();
  const [selectedTerminal, setSelectedTerminal] = useRecoilState(terminalCodeAtom);
  const [error] = useState<string | null>(null);
  const [occDateType, setOccDateType] = useState('HOURS_30_36');
  const { loadingCapacity, loadingOcc, capacityTemplates, capacityEntities, occ, capacityFetcher } = useCapacityData();
  const [selectedOccRow, setSelectedOccRow] = useState<any>(null);
  const [selectedDefaultRow, setSelectedDefaultRow] = useState(null);
  const [selectedWeeklyData, setSelectedWeeklyData] = useState(null);
  const [selectedWeeklyRow, setSelectedWeeklyRow] = useState([]);
  const [selectedWeeklyRows, setSelectedWeeklyRows] = useState([]);
  const [selectedAddDefaultValues, setSelectedAddDefaultValues] = useState(null);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!id) {
      return;
    }
    setSelectedTerminal(id);
  }, [id, setSelectedTerminal]);

  const handleSelectChange = async (selectedCode: string) => {
    if (!selectedCode) {
      return;
    }
    setSelectedTerminal(selectedCode);
  };

  // Function to handle row click in the default table
  const onDefaultRowClick = (e) => {
    const row = e.detail.rowData;
    setSelectedDefaultRow(row);
  };
  const onWeeklySelectChange = (e) => {
    setSelectedWeeklyRows(e.detail);
  };
  const onWeeklyRowClick = (e) => {
    const row = e.detail.rowData;

    // Check if multiple rows selected, if all numbers same then set it, else set all to 0 0 0
    setSelectedWeeklyData({
      // @ts-ignore
      moves: parseInt(row.moveCount),
      yardCapacity: parseInt(row.yardCapacity),
      reeferPlugs: parseInt(row.reeferPlugs),
      moveCountStretchPercentage: parseInt(row.moveCountStretchPercentage),
      moveCountStretchNumber: parseInt(row.moveCountStretchNumber),
      moveCountStretchNumberOfWeeks: parseInt(row.moveCountStretchNumberOfWeeks),
      moveCountCriticalPercentage: parseInt(row.moveCountCriticalPercentage),
      moveCountCriticalNumber: parseInt(row.moveCountCriticalNumber),
      moveCountCriticalNumberOfWeeks: parseInt(row.moveCountCriticalNumberOfWeeks),
      imoUnits: parseInt(row.imoUnits),
      imoUnitsDescription: row.imoUnitsDescription,
      oogUnits: parseInt(row.oogUnits),
      capacityDataType: row.capacityDataType,
    });

    // @ts-ignore
    setSelectedWeeklyRow([row.date]);
  };

  // @ts-ignore
  return (
    <div className="CapacityContainer">
      <EditOccValuesDrawer
        // @ts-ignore
        occValues={selectedOccRow}
        selectedTerminal={selectedTerminal}
        open={Boolean(selectedOccRow)}
        fetchRules={() => capacityFetcher()}
        setOpen={() => setSelectedOccRow(null)}
      />

      {selectedAddDefaultValues && (
        <AddDefaultValuesDrawer
          // @ts-ignore
          defaultValues={selectedAddDefaultValues}
          selectedTerminal={selectedTerminal}
          // @ts-ignore
          id={selectedDefaultRow?.id}
          open={Boolean(selectedAddDefaultValues)}
          fetchRules={() => capacityFetcher()}
          setOpen={() => setSelectedAddDefaultValues(null)}
        />
      )}

      {selectedDefaultRow && (
        <EditDefaultValuesDrawer
          // @ts-ignore
          defaultValues={selectedDefaultRow}
          selectedTerminal={selectedTerminal}
          // @ts-ignore
          id={selectedDefaultRow?.id}
          open={Boolean(selectedDefaultRow)}
          fetchRules={() => capacityFetcher()}
          setOpen={() => setSelectedDefaultRow(null)}
        />
      )}

      {selectedWeeklyData && (
        <EditWeeklyValuesDrawer
          // @ts-ignore
          defaultValues={selectedWeeklyData}
          selectedTerminal={selectedTerminal}
          open={selectedWeeklyRow.length > 0}
          selectedWeeks={selectedWeeklyRow}
          fetchRules={() => {
            setSelectedWeeklyRows([]);
            capacityFetcher();
          }}
          setOpen={() => setSelectedWeeklyRow([])}
        />
      )}

      <McSelect
        label={'Select terminal'}
        placeholder="Select terminal"
        value={selectedTerminal}
        optionselected={(e) => {
          // @ts-ignore
          handleSelectChange(e.target.value);
        }}
        name="terminal"
      >
        {userData?.terminalCodes &&
          terminals
            .filter((terminal) => userData.terminalCodes.includes(terminal.code))
            .map((terminal) => (
              <McOption key={terminal.code} value={terminal.code}>
                {terminal.name}
              </McOption>
            ))}
      </McSelect>

      {error && (
        <McNotification appearance="error" heading={formatMessage({ id: 'error' })}>
          <FormattedMessage id="genericErrorMessage" />
        </McNotification>
      )}
      <McTabBar>
        <McTab slot="tab" label="SOC" />
        <div slot="panel">
          {loadingCapacity ? (
            <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
          ) : (
            selectedTerminal && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <h3>Default values</h3>
                  </div>
                  <McButton
                    label="Add new start period"
                    appearance="neutral"
                    icon={'plus'}
                    variant="outlined"
                    click={() => {
                      // @ts-ignore
                      const lastEntry = capacityTemplates[capacityTemplates.length - 1];
                      const latestData = {
                        // @ts-ignore
                        ...lastEntry.entries.find((e) => e.capacityDataType === 'TOTAL'),
                        // @ts-ignore
                        startDate: modifyDateWeek(lastEntry.startDate.split('T')[0], 'add'),
                        // @ts-ignore
                        previousStartDate: lastEntry.startDate.split('T')[0],
                        nextStartDate: null,
                      };
                      // @ts-ignore
                      setSelectedAddDefaultValues(latestData);
                    }}
                  />
                </div>
                <McTable
                  ref={defaultTableRef}
                  columns={defaultColumns}
                  expand={isCarriersAvailableForTerminal(selectedTerminal)}
                  expandpadding="none"
                  datakey="id"
                  data={capacityTemplates.map(defaultDataMapper(capacityTemplates))}
                  rowclick={onDefaultRowClick}
                >
                  <TerminalCarriersRenderer
                    type="default"
                    columns={defaultColumns}
                    ref={defaultTableRef}
                    data={capacityTemplates.map(defaultDataMapper(capacityTemplates))}
                    dataKey="id"
                  />
                </McTable>
                <div>
                  <h3>Weekly values</h3>
                  {selectedWeeklyRows.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0.75rem 1rem',
                        backgroundColor: '#FAE5DA',
                        borderRadius: '4px',
                        marginBottom: '1rem',
                        border: '1px solid #FF7A59',
                      }}
                    >
                      <span style={{ fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                        {selectedWeeklyRows.length} week(s) selected
                      </span>
                      <McButton
                        label="Edit"
                        appearance="secondary"
                        variant="outlined"
                        padding="compact"
                        click={() => {
                          setSelectedWeeklyData({
                            // @ts-ignore
                            moves: parseInt(selectedWeeklyRows[0].moveCount),
                            // @ts-ignore
                            yardCapacity: parseInt(selectedWeeklyRows[0].yardCapacity),
                            // @ts-ignore
                            reeferPlugs: parseInt(selectedWeeklyRows[0].reeferPlugs),
                            // @ts-ignore
                            moveCountStretchPercentage: parseInt(selectedWeeklyRows[0].moveCountStretchPercentage),
                            // @ts-ignore
                            moveCountStretchNumber: parseInt(selectedWeeklyRows[0].moveCountStretchNumber),
                            // @ts-ignore
                            moveCountStretchNumberOfWeeks: selectedWeeklyRows[0].moveCountStretchNumberOfWeeks,
                            // @ts-ignore
                            moveCountCriticalPercentage: parseInt(selectedWeeklyRows[0].moveCountCriticalPercentage),
                            // @ts-ignore
                            moveCountCriticalNumber: parseInt(selectedWeeklyRows[0].moveCountCriticalNumber),
                            // @ts-ignore
                            moveCountCriticalNumberOfWeeks: selectedWeeklyRows[0].moveCountCriticalNumberOfWeeks,
                            // @ts-ignore
                            capacityDataType: selectedWeeklyRows[0].capacityDataType,
                            // @ts-ignore
                            imoUnits: parseInt(selectedWeeklyRows[0].imoUnits),
                            // @ts-ignore
                            oogUnits: parseInt(selectedWeeklyRows[0].oogUnits),
                          });
                          setSelectedWeeklyRow(
                            // @ts-ignore
                            selectedWeeklyRows.map((row) => row.date),
                          );
                        }}
                      />
                    </div>
                  )}
                  <McTable
                    // @ts-ignore
                    rowclick={onWeeklyRowClick}
                    selectchange={onWeeklySelectChange}
                    select={true}
                    expand={isCarriersAvailableForTerminal(selectedTerminal)}
                    expandpadding="none"
                    datakey="date"
                    ref={weeklyTableRef}
                    columns={weeklyColumns}
                    data={capacityEntities.map(weeklyDataMapper)}
                  >
                    <TerminalCarriersRenderer
                      type="weekly"
                      columns={weeklyColumns}
                      ref={weeklyTableRef}
                      data={capacityEntities}
                      dataKey="date"
                    />
                  </McTable>
                </div>
              </>
            )
          )}
        </div>

        <McTab slot="tab" label={'OCC'} />
        <div slot="panel">
          {loadingOcc ? (
            <McLoadingIndicator label={formatMessage({ id: 'fetchingData' })} />
          ) : (
            selectedTerminal && (
              <>
                <div>
                  <h3>Overlapping Connections Capacity</h3>
                  <McSegmentedControl
                    //@ts-ignore*/
                    listchange={(e) => setOccDateType(e.target.value)}
                  >
                    <McSegmentedControlItem selected={occDateType === 'HOURS_30_36'} value="HOURS_30_36">
                      30 - 36 h
                    </McSegmentedControlItem>
                    <McSegmentedControlItem value="HOURS_36_42">36 - 42 h</McSegmentedControlItem>
                    <McSegmentedControlItem value="HOURS_42_48">42 - 48 h</McSegmentedControlItem>
                    <McSegmentedControlItem value="HOURS_48_PLUS">{'> 48 h'}</McSegmentedControlItem>
                  </McSegmentedControl>
                  <div style={{ marginTop: '20px' }}>
                    <McTable
                      ref={occTableRef}
                      columns={[
                        { id: 'from', label: 'From (PC)', align: 'left' },
                        { id: 'to', label: 'To (OC)', align: 'left' },
                        { id: 'vesselPairLimits', label: 'Vessel pair limits', align: 'left' },
                      ]}
                      data={
                        occ &&
                        occ[occDateType] &&
                        occ[occDateType].capacityEntries.map((item, index) => ({
                          id: index,
                          from: mapCapacityType(item.from),
                          to: mapCapacityType(item.to),
                          vesselPairLimits: `${item.vesselPairLimits}`,
                          dateType: occDateType,
                        }))
                      }
                      rowclick={(e) => {
                        const row = e.detail.rowData;
                        setSelectedOccRow(row);
                      }}
                    />
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </McTabBar>
    </div>
  );
};

export default CapacityIdContainer;
