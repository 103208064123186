import './SideNav.scss';
import { McIcon } from '@maersk-global/mds-react-wrapper';
import cx from 'classnames';
import { AppRoutes } from '../../../../config/routes';
import { useGetCurrentRoute } from '../../hooks/useGetCurrentRoute';
import { useRedirectToRoute } from '../../hooks/useRedirectToRoute';
import { AdjustableComponent, useClasses } from '../../hooks/useClasses';

export type SideNavStyles = {
  root: string;
  item: string;
  itemSelected: string;
  itemDisabled: string;
  itemIcon: string;
  itemText: string;
};

export type SideNavItem = {
  title: string;
  route: AppRoutes;
  icon?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export type SideNavProps = {
  items: SideNavItem[];
};

const SideNav: AdjustableComponent<SideNavProps, SideNavStyles> = ({ items, classes }) => {
  const styles = useClasses(
    {
      root: 'SideNav',
      item: 'SideNav__item',
      itemSelected: 'SideNav__item--selected',
      itemDisabled: 'SideNav__item--disabled',
      itemIcon: 'SideNav__item-icon',
      itemText: 'SideNav__item-text',
    },
    classes,
  );

  const getCurrentRoute = useGetCurrentRoute();
  const currentRoute = getCurrentRoute();
  const redirectToRoute = useRedirectToRoute();

  const getClassNamesForItem = (item: SideNavItem) =>
    cx(styles.item, {
      [styles.itemSelected]: currentRoute === item.route,
      [styles.itemDisabled]: item.disabled,
    });

  const onItemClick = (item: SideNavItem) => {
    if (item.disabled) {
      return;
    }
    if (item.onClick) {
      item.onClick();
      return;
    }
    redirectToRoute(item.route);
  };

  return (
    <ul className={styles.root}>
      {items.map((item, index) => (
        <li key={index} onClick={() => onItemClick(item)} className={getClassNamesForItem(item)}>
          {item.icon && (
            <div className={styles.itemIcon}>
              <McIcon size="24" icon={item.icon} />
            </div>
          )}
          <span className={styles.itemText}>{item.title}</span>
        </li>
      ))}
    </ul>
  );
};

export default SideNav;
