import { CapacityDataType } from '../types';
import { modifyDate } from '../../common/helpers/modify-date';
import { terminals } from '../../common/helpers/terminals';

export const weeklyDataMapper = (template: any) => {
  const entry = template.entries.find((e) => e.capacityDataType === CapacityDataType.Total);
  return {
    date: `${template.date}`,
    moveCount: `${entry.moves}`,
    yardCapacity: `${entry.yardCapacity}`,
    moveCountStretchPercentage: entry.moveCountStretchPercentage,
    moveCountStretchNumber: entry.moveCountStretchNumber,
    moveCountStretch: entry.moveCountStretchPercentage
      ? `${entry.moveCountStretchNumber} (${entry.moveCountStretchPercentage}%)`
      : '',
    moveCountStretchNumberOfWeeks: entry.moveCountStretchNumberOfWeeks,
    moveCountCriticalNumber: entry.moveCountCriticalNumber,
    moveCountCritical: entry.moveCountCriticalPercentage
      ? `${entry.moveCountCriticalNumber} (${entry.moveCountCriticalPercentage}%)`
      : '',
    moveCountCriticalNumberOfWeeks: entry.moveCountCriticalNumberOfWeeks,
    moveCountCriticalPercentage: entry.moveCountCriticalPercentage,
    reeferPlugs: `${entry.reeferPlugs}`,
    imoUnits: entry.imoUnits,
    imoUnitsDescription: entry.imoUnitsDescription,
    oogUnits: entry.oogUnits,
    capacityDataType: entry.capacityDataType,
    overwrite: entry.overwrite,
  };
};
// @TODO: Replace types!
export const defaultDataMapper = (capacityTemplateEntities: any) => (template: any, index: number) => {
  const entry = template.entries.find((e) => e.capacityDataType === CapacityDataType.Total);
  const previousStartDate = capacityTemplateEntities[index - 1]?.startDate?.split('T')[0];
  const startDate = template.startDate.split('T')[0];
  const nextStartDate = capacityTemplateEntities[index + 1]?.startDate?.split('T')[0];

  return {
    id: template.terminalCodeAndStartDate,
    startDate: template.startDate,
    previousStartDate: previousStartDate,
    nextStartDate: nextStartDate,
    validityPeriod:
      startDate.replaceAll('-', '/') +
      ' - ' +
      (nextStartDate ? modifyDate(nextStartDate, 'remove').replaceAll('-', '/') : '2100/01/01'),
    moveCount: entry.moves,
    moves: entry.moves,
    moveCountStretchPercentage: entry.moveCountStretchPercentage,
    moveCountCriticalPercentage: entry.moveCountCriticalPercentage,
    moveCountStretchNumber: entry.moveCountStretchNumber,
    moveCountCriticalNumber: entry.moveCountCriticalNumber,
    moveCountStretchNumberOfWeeks: entry.moveCountStretchNumberOfWeeks,
    moveCountCriticalNumberOfWeeks: entry.moveCountCriticalNumberOfWeeks,
    moveCountStretch: entry.moveCountStretchPercentage
      ? `${entry.moveCountStretchNumber} (${entry.moveCountStretchPercentage}%)`
      : '',
    moveCountCritical: entry.moveCountCriticalPercentage
      ? `${entry.moveCountCriticalNumber} (${entry.moveCountCriticalPercentage}%)`
      : '',
    yardCapacity: entry.yardCapacity,
    reeferPlugs: entry.reeferPlugs,
    imoUnits: entry.imoUnits,
    imoUnitsDescription: entry.imoUnitsDescription,
    oogUnits: entry.oogUnits,
    entries: fillCarriers(
      template.terminalCode,
      template.entries.filter((e) => e.capacityDataType !== CapacityDataType.Total),
    ),
    overwrite: entry.overwrite,
    status: entry.overwrite ? 'Adjusted' : 'Default',
  };
};

export const fillCarriers = (terminalCode, entries) => {
  const terminal = terminals.find((terminal) => terminal.code === terminalCode);
  // @ts-ignore
  const existingCarriers = entries
    .filter((e) => e.capacityDataType !== CapacityDataType.Total)
    .map((e) => e.capacityDataType);
  // @ts-ignore
  const missingCarriers = terminal.carriers.filter((e) => !existingCarriers.includes(e));

  return [
    ...entries,
    ...missingCarriers.map((carrier) => ({
      capacityDataType: carrier,
      moves: 0,
      yardCapacity: 0,
      reeferPlugs: 0,
      moveCountStretchPercentage: 0,
      moveCountStretchNumber: 0,
      moveCountStretchNumberOfWeeks: 0,
      moveCountCriticalPercentage: 0,
      moveCountCriticalNumber: 0,
      moveCountCriticalNumberOfWeeks: 0,
      imoUnits: 0,
      imoUnitsDescription: null,
      oogUnits: 0,
      overwrite: 0,
    })),
  ].map((e) => ({
    ...e,
    moveCount: e.moves,
  }));
};
