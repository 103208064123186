import { TagAppearance } from '@maersk-global/mds-components-core/types';
import { McButton, McInput, McTag } from '@maersk-global/mds-react-wrapper';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useRecoilState } from 'recoil';
import { DependencyContainer } from '../../../http/DependencyContainer';
import { carriersUnderEditAtom, CarrierUpdateDto } from '../../../state';
import FormattedMessage from '../../common/FormattedMessage';
import { pushOrUpdateCollection } from '../../common/helpers/collections';
import { useIntl } from '../../common/hooks/useIntl';
import { ModalContext, ModalType } from '../../common/modals/ModalProvider';
import { carrierService } from '../../common/service/carrier/carrier-service';
import { Translation } from '../../common/types';
import { defaultColumns, weeklyColumns } from '../config/capacity-columns';
import { CapacityEntity, CapacityEntityEntry } from '../types';
import useCapacityData from './useCapacityData';

type CarrierColumnsDefinition = {
  fieldName: string;
  render?: (carrier: CapacityEntityEntry, editMode: boolean) => any;
};

export type ColumnsDefinitionType = 'default' | 'weekly';

const { octwService } = new DependencyContainer();

const useCarrierOperations = () => {
  const [carriersUnderEdit, setCarriersUnderEdit] = useRecoilState(carriersUnderEditAtom);
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const { capacityFetcher, capacityEntities, setCapacityEntities } = useCapacityData();
  const { showModal } = useContext(ModalContext);

  let defaultCarrierColumnsDefinition = defaultColumns.map((defaultColumn) => ({
    fieldName: defaultColumn.id,
  }));
  let weeklyCarrierColumnsDefinition = weeklyColumns.map((weeklyColumn) => ({
    fieldName: weeklyColumn.id,
  }));

  const getCarrierUnderEditIndex = (carrierId: string, item: CapacityEntity, type: ColumnsDefinitionType) => {
    return carriersUnderEdit.findIndex((cue) => {
      const fieldToCompare: keyof CapacityEntity = type === 'default' ? 'date' : 'id';
      return cue.carrierId === carrierId && item[fieldToCompare] === cue.entryId && cue.type === type;
    });
  };

  const isCarrierUnderEdit = (carrierId: string, item: CapacityEntity, type: ColumnsDefinitionType): boolean => {
    const foundIndex = getCarrierUnderEditIndex(carrierId, item, type);
    return foundIndex !== -1;
  };

  const updateDtoProperty = (property: keyof CarrierUpdateDto, value: any) => {
    // @TODO: Change this logic to not retrieve first element from array
    //  if we need to have possibility to edit multiple rows at once
    setCarriersUnderEdit((current) => [
      {
        ...current[0],
        dto: {
          ...current[0].dto,
          [property]: value,
        },
      },
    ]);
  };

  const updateDto = (id: string, value: CapacityEntityEntry[]) => {
    const dtoToUpdateIndex = capacityEntities.findIndex((entity) => entity.id === id);
    const dtoToUpdate = { ...capacityEntities[dtoToUpdateIndex] };
    if (dtoToUpdate) {
      dtoToUpdate.entries = value;
      const newCapacityEntities = [...capacityEntities];
      newCapacityEntities[dtoToUpdateIndex] = dtoToUpdate;
      setCapacityEntities(newCapacityEntities);
    }
  };

  const getDto = (): CarrierUpdateDto => {
    if (!carriersUnderEdit || carriersUnderEdit?.length === 0) {
      return null;
    }
    return carriersUnderEdit[0].dto;
  };

  const getDtoProperty = (property: keyof CarrierUpdateDto) => {
    const dto = getDto();
    if (!dto) {
      return '';
    }
    return dto[property] || '';
  };

  const onEditClick = (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    const entryId = columnsDefinitionType === 'default' ? item.date : item.id;
    setCarriersUnderEdit([
      {
        carrierId: carrier.capacityDataType,
        entryId,
        //@ts-ignore
        week: item.date,
        //@ts-ignore
        terminalCode: item.terminalCode,
        //@ts-ignore
        terminalCodeAndStartDate: item.id,
        startDate: item.startDate,
        type: columnsDefinitionType,
        dto: {
          ...carrier,
        },
      },
    ]);
  };

  const onCancelEditClick = (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    const foundIndex = getCarrierUnderEditIndex(carrier.capacityDataType, item, columnsDefinitionType);
    setCarriersUnderEdit((prev) => {
      return prev.filter((_, index) => index !== foundIndex);
    });
  };

  const onSaveClick = async (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    try {
      const data = carriersUnderEdit[0];
      if (data.type === 'default') {
        // Update default data
        await octwService.updateDefaultValues({
          ...data.dto,
          capacityDataType: data.carrierId,
          // @ts-ignore
          id: data.terminalCodeAndStartDate,
          // @ts-ignore
          startDate: data.startDate,
        });
        await capacityFetcher();
      } else {
        // Update weekly data
        await octwService.updateWeeklyValues({
          ...data.dto,
          capacityDataType: data.carrierId,
          // @ts-ignore
          terminalCode: data.terminalCode,
          // @ts-ignore
          weeks: [data.week],
        });
        await capacityFetcher();
      }
      enqueueSnackbar(formatMessage({ id: 'carrierUpdatedMessage' }), {
        variant: 'success',
      });
      setCarriersUnderEdit([]);
    } catch (error) {
      enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), {
        variant: 'success',
      });
      console.error('Error during saving carrier', error);
    }
  };

  const onResetClick = (
    carrier: CapacityEntityEntry,
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
  ) => {
    if (columnsDefinitionType === 'weekly') {
      carrierService()
        .resetCapacity({ rowId: item.id, type: carrier.capacityDataType })
        .then((result) => {
          if (result) {
            updateDto(item.id, result.entries);
          } else {
            enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
          }
        });
    }
  };

  const onInputField = (value: any, fieldName: keyof CapacityEntityEntry) => {
    updateDtoProperty(fieldName, value);
    return value;
  };

  const withClassicEditInput = (
    carrier: CapacityEntityEntry,
    editMode: boolean,
    fieldName: keyof CapacityEntityEntry,
  ) => {
    if (!editMode) {
      return carrier[fieldName];
    }
    const value = getDtoProperty(fieldName);
    return (
      <McInput
        hiddenlabel
        name={fieldName}
        value={value}
        input={(e: any) => onInputField(e.target.value, fieldName)}
        fit="small"
      />
    );
  };

  // For rendering field which consists with two or more fields e.g Thresholds
  const withThresholdInput = (
    carrier: CapacityEntityEntry,
    editMode: boolean,
    fieldNames: Array<keyof CapacityEntityEntry>,
  ) => {
    if (!editMode) {
      return carrier[fieldNames[0]] ? `${carrier[fieldNames[0]]} (${carrier[fieldNames[1]]}%)` : '';
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
        {fieldNames.map((fieldName, index) => (
          <McInput
            key={fieldName + index}
            hiddenlabel
            name={fieldName}
            value={getDtoProperty(fieldName)}
            input={(e: any) => onInputField(e.target.value, fieldName)}
            fit="small"
          />
        ))}
      </div>
    );
  };

  const renderStatus = (isOverwritten: boolean) => {
    const appearance: TagAppearance = isOverwritten ? 'warning' : 'neutral';
    const messageId: keyof Translation = isOverwritten ? 'statusAdjusted' : 'statusDefault';
    return (
      <McTag appearance={appearance} fit="small">
        <FormattedMessage id={messageId} />
      </McTag>
    );
  };

  const getCarrierColumnsDefinitions = (
    columnsDefinitionType: ColumnsDefinitionType,
    item: CapacityEntity,
    isOverwritten: boolean,
  ): CarrierColumnsDefinition[] => {
    let collection =
      columnsDefinitionType === 'default' ? defaultCarrierColumnsDefinition : weeklyCarrierColumnsDefinition;

    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'actions',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => {
          const withOperationsArgs = (
            carrier: CapacityEntityEntry,
            type: ColumnsDefinitionType,
            item: CapacityEntity,
          ) => {
            return (func: CallableFunction) => {
              func(carrier, type, item);
            };
          };
          const operation = withOperationsArgs(carrier, columnsDefinitionType, item);
          const commonStyles = {
            display: 'flex',
            justifyContent: 'flex-end',
          };
          return (
            <>
              {editMode ? (
                <div style={commonStyles}>
                  <McButton
                    fit="small"
                    click={() => operation(onCancelEditClick)}
                    variant="plain"
                    appearance="neutral"
                    icon="times"
                  >
                    <FormattedMessage id="cancel" />
                  </McButton>
                  <McButton
                    fit="small"
                    click={() => operation(onSaveClick)}
                    variant="plain"
                    appearance="neutral"
                    icon="check"
                  >
                    <FormattedMessage id="save" />
                  </McButton>
                </div>
              ) : (
                <div style={commonStyles}>
                  {columnsDefinitionType === 'weekly' && isOverwritten && (
                    <McButton
                      fit="small"
                      click={() => showModal(ModalType.ResetCarrier, () => () => operation(onResetClick))}
                      variant="plain"
                      icon="arrow-anti-clockwise"
                      appearance="neutral"
                      disabled={!isOverwritten}
                    >
                      <FormattedMessage id="statusDefault" />
                    </McButton>
                  )}
                  <McButton
                    fit="small"
                    click={() => operation(onEditClick)}
                    variant="plain"
                    appearance="neutral"
                    icon="pencil"
                  >
                    <FormattedMessage id="edit" />
                  </McButton>
                </div>
              )}
            </>
          );
        },
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'date',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => null,
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'moveCount',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => withClassicEditInput(carrier, editMode, 'moves'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'moveCountStretch',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withThresholdInput(carrier, editMode, ['moveCountStretchNumber', 'moveCountStretchPercentage']),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'moveCountCritical',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withThresholdInput(carrier, editMode, ['moveCountCriticalNumber', 'moveCountCriticalPercentage']),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'status',
        render: (carrier: CapacityEntityEntry, editMode: boolean) => renderStatus(Boolean(carrier?.overwrite)),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'yardCapacity',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'yardCapacity'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'reeferPlugs',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'reeferPlugs'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'imoUnits',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'imoUnits'),
      },
      'fieldName',
    );
    collection = pushOrUpdateCollection<CarrierColumnsDefinition>(
      collection,
      {
        fieldName: 'oogUnits',
        render: (carrier: CapacityEntityEntry, editMode: boolean) =>
          withClassicEditInput(carrier, editMode, 'oogUnits'),
      },
      'fieldName',
    );
    return collection;
  };

  return { getCarrierColumnsDefinitions, isCarrierUnderEdit, renderStatus, updateDto };
};

export default useCarrierOperations;
