import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { McButton } from '@maersk-global/mds-react-wrapper';
import { useSnackbar } from 'notistack';
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { terminalCodeAtom } from '../../../../state';
import FormattedMessage from '../../../common/FormattedMessage';
import { getCarrierById } from '../../../common/helpers/carriers';
import { useIntl } from '../../../common/hooks/useIntl';
import { ModalContext, ModalType } from '../../../common/modals/ModalProvider';
import { carrierService } from '../../../common/service/carrier/carrier-service';
import { fillCarriers } from '../../config/mappers';
import useCarrierColumnsHeader from '../../hooks/useCarrierColumnsHeader';
import useCarrierOperations, { ColumnsDefinitionType } from '../../hooks/useCarrierOperations';
import { CapacityDataType, CapacityEntity, CapacityEntityEntry } from '../../types';

type TerminalCarriersRendererProps = {
  data: any[];
  columns: TableColumn[];
  dataKey: string;
  type: ColumnsDefinitionType;
};

const TerminalCarriersRenderer = forwardRef<HTMLDivElement, TerminalCarriersRendererProps>(
  ({ columns, data, dataKey, type }, ref) => {
    const [columnsWidths, setColumnsWidths] = useState<number[]>([]);
    const [margin, setMargin] = useState<number>(0);
    const { getCarrierColumnsDefinitions, isCarrierUnderEdit, renderStatus, updateDto } = useCarrierOperations();
    const { getCustomHeaders } = useCarrierColumnsHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();
    const { showModal } = useContext(ModalContext);
    const terminalCode = useRecoilValue(terminalCodeAtom);

    const getCarriersByEntries = (item: CapacityEntity): CapacityEntityEntry[] => {
      return item.entries?.filter((entry) => entry.capacityDataType !== CapacityDataType.Total) || [];
    };

    const calculateColumnsWidths = () => {
      // @ts-ignore
      if (ref == null || ref.current == null) {
        return;
      }

      // @ts-ignore
      const parentRow = ref.current.shadowRoot.querySelector('tr:first-child');
      const parentRowColumns = parentRow.querySelectorAll('th');
      // @ts-ignore
      const parentRowColumnsWidths = Array.from(parentRowColumns).map((p) => p?.getBoundingClientRect()?.width);
      //@ts-ignore
      const carrierLogoCellWidth = parentRowColumnsWidths[1] + parentRowColumnsWidths[2];
      const refinedRowColumnsWidths = [carrierLogoCellWidth, ...parentRowColumnsWidths.slice(3)];
      setColumnsWidths(refinedRowColumnsWidths);
      setMargin(parentRowColumnsWidths[1]);
    };

    const renderCarrierCell = (carrier: CapacityEntityEntry, index: number, item: CapacityEntity) => {
      const carrierData = getCarrierById(carrier.capacityDataType);
      const carrierColumnsDefinitions = getCarrierColumnsDefinitions(type, item, Boolean(carrier.overwrite));
      try {
        const cell = carrierColumnsDefinitions[index];

        if (cell.fieldName === 'carrier') {
          return (
            <div className="TerminalCarriersRenderer__logo">
              {carrierData.image && <carrierData.image />}
              {carrierData.name}
            </div>
          );
        }
        const value = carrier[cell.fieldName] || '';
        return cell.render?.(carrier, isCarrierUnderEdit(carrier.capacityDataType, item, type)) || value;
      } catch (error) {
        return null;
      }
    };

    const getColumnWidth = (column: TableColumn, index: number) => {
      return columnsWidths[index];
    };

    const renderCarrierRow = (carrier: CapacityEntityEntry, item: CapacityEntity) => {
      return (
        <tr key={carrier.capacityDataType} className="TerminalCarriersRenderer__row">
          <td style={{ width: `${margin}px` }} className="TerminalCarriersRenderer__cell" />
          {columns.map((column, i) => (
            <td key={i} className="TerminalCarriersRenderer__cell" style={{ width: `${getColumnWidth(column, i)}px` }}>
              {renderCarrierCell(carrier, i, item)}
            </td>
          ))}
        </tr>
      );
    };

    const resetValues = (rowId: string) => {
      carrierService()
        .resetCapacity({ rowId, type: CapacityDataType.Total })
        .then((result) => {
          if (result) {
            updateDto(rowId, result.entries);
          } else {
            enqueueSnackbar(formatMessage({ id: 'genericErrorMessage' }), { variant: 'error' });
          }
        });
    };

    useEffect(() => {
      // @ts-ignore
      if (ref == null || ref.current == null) {
        return;
      }

      setTimeout(() => {
        calculateColumnsWidths();
      }, 400);

      window.addEventListener('resize', calculateColumnsWidths);

      return () => {
        window.removeEventListener('resize', calculateColumnsWidths);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps -- by design
    }, [ref]);

    return (
      <>
        {getCustomHeaders().map((header) => (
          <div slot={`${header.id}_header`}>{header.render()}</div>
        ))}
        {data.map((item, index) => {
          const slotPrefix = item[dataKey];
          const isOverwritten = item?.entries?.find((e) => e.capacityDataType === CapacityDataType.Total)?.overwrite;
          return (
            <React.Fragment key={index}>
              <div slot={`${slotPrefix}_status`}>{renderStatus(Boolean(isOverwritten))}</div>
              <div slot={`${slotPrefix}_actions`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {type === 'weekly' && isOverwritten && (
                  <McButton
                    fit="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal(ModalType.ResetWeekly, () => () => resetValues(item.id));
                    }}
                    variant="plain"
                    icon="arrow-anti-clockwise"
                    appearance="neutral"
                  >
                    <FormattedMessage id="statusDefault" />
                  </McButton>
                )}
                <McButton fit="small" variant="plain" appearance="neutral" icon="pencil">
                  Edit
                </McButton>
              </div>
            </React.Fragment>
          );
        })}
        {data.map((item, dataIndex) => {
          // @ts-ignore
          const availableCarriersForItem = fillCarriers(terminalCode, getCarriersByEntries(item));
          if (!availableCarriersForItem || availableCarriersForItem.length === 0) {
            return null;
          }
          return (
            <div key={dataIndex} slot={`${item[dataKey]}_expanded`} className="TerminalCarriersRenderer">
              <table cellSpacing={0}>
                {availableCarriersForItem.map((carrier) => renderCarrierRow(carrier, item))}
              </table>
            </div>
          );
        })}
      </>
    );
  },
);

export default TerminalCarriersRenderer;
