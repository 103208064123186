import { useRedirectToRoute } from './useRedirectToRoute';
import { SideNavItem } from '../components/SideNav/SideNav';
import { useIntl } from './useIntl';
import { AppRoutes } from '../../../config/routes';
import useAuth from './useAuth';

export const useSideNavItems = (): SideNavItem[] => {
  const redirectToRoute = useRedirectToRoute();
  const { formatMessage } = useIntl();

  const { userData, isAdmin } = useAuth();

  const items = [
    isAdmin
      ? {
          title: formatMessage({
            id: 'sideNavOctw',
          }),
          icon: 'vessel-side',
          route: AppRoutes.OctwRules,
          onClick: () => {
            redirectToRoute(AppRoutes.OctwRules);
          },
        }
      : undefined,
    {
      title: formatMessage({
        id: 'sideNavCapacity',
      }),
      icon: 'chart-line-up',
      route: AppRoutes.CapacityId,
      onClick: () => {
        redirectToRoute(AppRoutes.CapacityId, { id: userData?.terminalCodes?.[0] });
      },
    },
    isAdmin
      ? {
          title: formatMessage({
            id: 'sideNavUsers',
          }),
          icon: 'people',
          route: AppRoutes.Users,
          onClick: () => {
            redirectToRoute(AppRoutes.Users, { id: userData?.terminalCodes?.[0] });
          },
        }
      : undefined,
  ].filter(Boolean);

  return items as SideNavItem[];
};
