export const en = {
  terminal: 'Terminal',
  code: 'Code',
  actions: 'Actions',
  cancel: 'Cancel',
  save: 'Save',
  edit: 'Edit',
  reset: 'Reset',
  back: 'Back',
  octwPageTitle: 'Operational Connecting Time Windows',
  capacityPageTitle: 'Capacity',
  usersPageTitle: 'User management',
  loadingTerminals: 'Fetching data...',
  fetchingData: 'Fetching data...',
  rules: 'Rules',
  capacityContainerTitle: 'Capacity',
  selectTerminal: 'Select terminal',
  octwRuleCardTooltipTitle: 'Operational Connecting Time Window',
  octwRuleCardTooltipContent:
    'Minimum time between ETA’s of 2 vessels for a terminal to operate TEU from one to the other vessel.',

  sideNavOctw: 'oCTW',
  sideNavCapacity: 'Capacity',
  sideNavUsers: 'Users',

  user_dropdown_version: 'Version',
  user_dropdown_version_frontend: 'UI: {version}',
  user_dropdown_logout: 'Log out',
  error: 'Error',
  genericErrorMessage: 'Something went wrong',
  octwFormattedText: '{octw, plural, one {# minute} other {# minutes}}',
  octwAdminSubheader: 'Pre-carrier ETA > On-carrier ETD',
  octwAdminTableColumnTerminal: 'Terminal',
  octwAdminTableColumnCode: 'Code',
  octwAdminTableColumnRuleName: 'Rule name',
  defaultOctwAdminTableColumnOtw: 'Default OTW',
  exceptionalOctwAdminTableColumnOtw: 'Exceptional OTW',
  updateRuleDrawerTitle: 'Edit rule value',
  updateRuleDrawerFormTerminal: 'Terminal',
  updateRuleDrawerFormCode: 'Code',
  updateRuleDrawerFormRuleName: 'Rule Name',
  updateRuleDrawerFormDefaultOctw: 'Default OCTW',
  updateRuleDrawerFormExceptionalOctw: 'Exceptional OCTW',
  updateRuleDrawerFormSubmit: 'Submit',
  updateRuleDrawerFormSubmitting: 'Submitting...',
  updateRuleSuccessMessage: 'Rule has been updated',
  carrierUpdatedMessage: 'Carrier has been updated',
  statusAdjusted: 'Adjusted',
  statusDefault: 'Default',
  threshold1: 'Threshold #1',
  threshold2: 'Threshold #2',
  resetCarrierCapacityModalHeading: 'Reset carrier values?',
  resetCarrierCapacityModalPrompt:
    'Are you sure you want to reset the values for this carrier? This action cannot be undone.',
  resetWeeklyValuesModalHeading: 'Reset weekly values?',
  resetWeeklyValuesModalPrompt:
    'Are you sure you want to reset the values for this entry? All values will be set to default.',
  imoDescriptionHeader: 'IMO comments',
  imoDescriptionPrompt:
    'Enter instructions or special notes for IMO containers here. Please include any specific handling requirements or additional information needed.',
};
