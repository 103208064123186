export const calculateAndSetValues = (formikRef, value, setFieldValue) => {
  const moves = value ? parseInt(value) : 0;

  // Recalculate moveCountStretchNumber based on the current percentage
  const stretchPercentage = parseFloat(formikRef.current.values.moveCountStretchPercentage) || 0;
  const newStretchValue = Math.round(moves + (moves * stretchPercentage) / 100);
  setFieldValue('moveCountStretchNumber', newStretchValue);

  // Recalculate moveCountCriticalNumber based on the current percentage
  const criticalPercentage = parseFloat(formikRef.current.values.moveCountCriticalPercentage) || 0;
  const newCriticalValue = Math.round(moves + (moves * criticalPercentage) / 100);
  setFieldValue('moveCountCriticalNumber', newCriticalValue);
};

export const handlePercentageChange = (formikRef, fieldName, dependentFieldName, formValues, setFormValues) => (e) => {
  let input = e.target.value.replace(',', '.');

  if (input === '' || input === '.' || input.match(/^(\d+)?(\.\d*)?$/)) {
    // If the input is empty, reset both the current and dependent fields
    if (input === '') {
      // @ts-ignore
      formikRef.current?.setFieldValue(fieldName, '');
      // @ts-ignore
      formikRef.current?.setFieldValue(dependentFieldName, null);
      setFormValues({
        ...formValues,
        [fieldName]: '',
      });
      return;
    }

    if (input === '.') {
      // @ts-ignore
      formikRef.current?.setFieldValue(fieldName, input);
      // @ts-ignore
      formikRef.current?.setFieldValue(dependentFieldName, null);
      setFormValues({
        ...formValues,
        [fieldName]: input,
      });
      return;
    }

    // Check for more than 2 decimal places
    const decimalPlaces = input.split('.')[1];
    if (decimalPlaces && decimalPlaces.length > 2) {
      // @ts-ignore
      formikRef.current?.setFieldValue(fieldName, formValues[fieldName]);
      return;
    }

    const percentage = parseFloat(input);
    if (!isNaN(percentage)) {
      // @ts-ignore
      const moves = formikRef.current?.values.moves || 0;
      const calculatedValue = Math.round(moves + (moves * percentage) / 100);

      // @ts-ignore
      formikRef.current?.setFieldValue(fieldName, input);
      // @ts-ignore
      formikRef.current?.setFieldValue(dependentFieldName, calculatedValue);
      setFormValues({
        ...formValues,
        [fieldName]: input,
      });
    }
  } else {
    // If invalid input, revert to the last valid value
    // @ts-ignore
    formikRef.current?.setFieldValue(fieldName, formValues[fieldName] || '');
  }
};
