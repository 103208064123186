import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import Drawer from '../../../common/components/Drawer/Drawer';
import { AdjustableComponent, useClasses } from '../../../common/hooks/useClasses';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Maybe } from '../../../common/types';
import FormikInputField from '../../../common/components/FormikInputField/FormikInputField';
import { McButton } from '@maersk-global/mds-react-wrapper';
import z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { DependencyContainer } from '../../../../http/DependencyContainer';
import { useSnackbar } from 'notistack';
import { useIntl } from '../../../common/hooks/useIntl';
import FormattedMessage from '../../../common/FormattedMessage';
import { createWeeklyValueDto } from '../dto/WeeklyValueDto';
import IMOInputField from '../IMODescription/IMOInputField';
import { calculateAndSetValues, handlePercentageChange } from '../utils/percentageCalculation';

export type EditWeeklyValuesDrawerStyles = {
  field: string;
  buttons: string;
};

export type EditWeeklyValuesDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchRules: () => void;
  selectedTerminal: string;
  defaultValues: Map<string, number>;
  selectedWeeks: string[];
};

export type EditRuleFormShape = {
  terminalCode: Maybe<string>;
  moves: Maybe<number>;
  yardCapacity: Maybe<number>;
  reeferPlugs: Maybe<number>;
  imoUnits: Maybe<number>;
  imoUnitsDescription: Maybe<string>;
  oogUnits: Maybe<number>;
  selectedWeeks: Maybe<string[]>;
  moveCountStretchPercentage: Maybe<number>;
  moveCountStretchNumber: Maybe<number>;
  moveCountStretchNumberOfWeeks: Maybe<number>;
  moveCountCriticalPercentage: Maybe<number>;
  moveCountCriticalNumber: Maybe<number>;
  moveCountCriticalNumberOfWeeks: Maybe<number>;
  capacityDataType: string;
};

const FormValidationSchema = z.object({
  terminalCode: z.string(),
  moves: z.number({ message: 'Value should be a number' }).positive(),
  yardCapacity: z.number({ message: 'Value should be a number' }).positive(),
  reeferPlugs: z.number({ message: 'Value should be a number' }).positive(),
});

const { octwService } = new DependencyContainer();

const EditWeeklyValuesDrawer: AdjustableComponent<EditWeeklyValuesDrawerProps, EditWeeklyValuesDrawerStyles> = ({
  classes,
  open,
  setOpen,
  fetchRules,
  selectedTerminal,
  selectedWeeks,
  defaultValues,
}) => {
  const styles = useClasses(
    {
      field: 'EditWeeklyValuesDrawer__field',
      buttons: 'EditWeeklyValuesDrawer__buttons',
    },
    classes,
  );
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const [formValues, setFormValues] = useState({
    moveCountStretchPercentage: defaultValues['moveCountStretchPercentage'],
    moveCountCriticalPercentage: defaultValues['moveCountCriticalPercentage'],
  });
  const formikRef = useRef(null);

  const onClose = () => {
    setOpen(false);
  };

  const getFormInitialValues = (): EditRuleFormShape => {
    return {
      terminalCode: selectedTerminal,
      selectedWeeks: selectedWeeks,
      moves: defaultValues['moves'],
      yardCapacity: defaultValues['yardCapacity'],
      reeferPlugs: defaultValues['reeferPlugs'],
      imoUnits: defaultValues['imoUnits'],
      imoUnitsDescription: defaultValues['imoUnitsDescription'],
      oogUnits: defaultValues['oogUnits'],
      moveCountStretchPercentage: defaultValues['moveCountStretchPercentage'],
      moveCountStretchNumber: defaultValues['moveCountStretchNumber'],
      moveCountStretchNumberOfWeeks: defaultValues['moveCountStretchNumberOfWeeks'],
      moveCountCriticalPercentage: defaultValues['moveCountCriticalPercentage'],
      moveCountCriticalNumber: defaultValues['moveCountCriticalNumber'],
      moveCountCriticalNumberOfWeeks: defaultValues['moveCountCriticalNumberOfWeeks'],
      capacityDataType: defaultValues['capacityDataType'],
    };
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const dto = createWeeklyValueDto(values);
    try {
      await octwService.updateWeeklyValues(dto);
      enqueueSnackbar('Weekly values updated', {
        variant: 'success',
      });
      await fetchRules();
      onClose();
    } catch (error) {
      const message = formatMessage({ id: 'genericErrorMessage' });
      enqueueSnackbar(message, {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Drawer title={'Edit weekly values'} open={open} setOpen={setOpen} onRequestClose={onClose} noFooter>
      <h5>{selectedWeeks.join(', ')}</h5>
      <hr />

      <Formik
        initialValues={getFormInitialValues()}
        enableReinitialize
        validationSchema={toFormikValidationSchema(FormValidationSchema)}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting }) => (
          <Form>
            <div style={{ backgroundColor: '#f0f0f0', padding: '0.5rem', borderRadius: '0.25rem' }}>
              <div style={{ display: 'flex', gap: '2rem', alignItems: 'flex-start' }}>
                {/* Move Count */}
                <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                  <label>Move count</label>
                  <Field
                    label={''}
                    type="number"
                    name="moves"
                    component={FormikInputField}
                    input={(e) => {
                      // @ts-ignore
                      formikRef.current.setFieldValue('moves', parseInt(e.target.value));
                      // @ts-ignore
                      calculateAndSetValues(formikRef, e.target.value, formikRef.current.setFieldValue);
                    }}
                  />
                  <ErrorMessage name="moves" component="div" />
                </div>

                {/* Threshold #1 with Icon and Total */}
                <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <span style={{ backgroundColor: '#FFD029', width: '1rem', height: '1rem' }}></span>
                    <label>Threshold L2</label>
                  </div>
                  <Field
                    label={''}
                    type="number"
                    name="moveCountStretchPercentage"
                    component={FormikInputField}
                    // @ts-ignore
                    input={handlePercentageChange(
                      formikRef,
                      'moveCountStretchPercentage',
                      'moveCountStretchNumber',
                      formValues,
                      setFormValues,
                    )}
                  />
                  <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#8A8A90' }}>
                    {/*//@ts-ignore*/}
                    Total: {formikRef.current?.values.moveCountStretchNumber}
                  </div>
                </div>

                {/* Threshold #2 with Icon and Total */}
                <div style={{ flexBasis: '30%', textAlign: 'left' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    <span style={{ backgroundColor: '#FF6000', width: '1rem', height: '1rem' }}></span>
                    <label>Threshold L3</label>
                  </div>
                  <Field
                    label={''}
                    type="number"
                    name="moveCountCriticalPercentage"
                    component={FormikInputField}
                    // @ts-ignore
                    input={handlePercentageChange(
                      formikRef,
                      'moveCountCriticalPercentage',
                      'moveCountCriticalNumber',
                      formValues,
                      setFormValues,
                    )}
                  />
                  <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#8A8A90' }}>
                    {/*// @ts-ignore*/}
                    Total: {formikRef.current?.values.moveCountCriticalNumber}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Field label={'Yard capacity'} type="number" name="yardCapacity" component={FormikInputField} />
              <ErrorMessage name="yardCapacity" component="div" />
              <Field label={'Reefer plugs'} type="number" name="reeferPlugs" component={FormikInputField} />
              <ErrorMessage name="reeferPlugs" component="div" />
            </div>

            <br />
            <div style={{ display: 'flex', gap: '1rem' }}>
              <IMOInputField isDrawerOpen={open} />
              <Field label={'OOG units'} type="number" name="oogUnits" component={FormikInputField} />
              <ErrorMessage name="oogUnits" component="div" />
            </div>

            <Field type="hidden" name="hubId" />
            <div className={styles.buttons}>
              <McButton disabled={isSubmitting} type="submit">
                <FormattedMessage id={isSubmitting ? 'updateRuleDrawerFormSubmitting' : 'updateRuleDrawerFormSubmit'} />
              </McButton>
              <McButton type="button" appearance="neutral" click={() => onClose()}>
                <FormattedMessage id="cancel" />
              </McButton>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default EditWeeklyValuesDrawer;
